import ApiService from "@/common/api.service";

export default {
  getClassBySlug(slug){
    return ApiService.query(`/teaching-classes/slug/${slug}`);
  },
  studentJoinClass(classId) {
    return ApiService.post(`/teaching-classes/${classId}/join`);
  },
  createClass(data) {
    return ApiService.post("/teaching-classes", data);
  },
  getClassDetail(id) {
    return ApiService.query(`/teaching-classes/${id}`);
  },
  updateClass(id, data) {
    return ApiService.patch(`/teaching-classes/${id}`, data);
  }
};
